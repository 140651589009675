import {
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { StorageService } from "./storage.service";
import { AuthorizationService } from "./authorization.service";
import { catchError } from "rxjs/operators";
import { ErrorMessages } from "../constants/utility.constants";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _authService: AuthorizationService
  ) {}

  private getRequestOptionArgs(request: HttpRequest<any>): HttpHeaders {
    const token = encodeURIComponent(StorageService.getToken());
    request.headers.append(
      "Authorization",
      "Bearer " + decodeURIComponent(token)
    );
    return request.headers;
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = StorageService.getToken();
    // Skip modifying headers for FormData
    if (request.body instanceof FormData) {
      if (token) {
        // Only add the Authorization header for FormData
        request = request.clone({
          setHeaders: {
            Authorization: token,
          },
        });
      }
      return next.handle(request);
    }

    // Default handling for non-FormData requests
    if (token) {
      const headers = request.url.includes("import")
        ? { Authorization: token } // Special case for "import" URLs
        : { "Content-Type": "application/json", Authorization: token };

      request = request.clone({ setHeaders: headers });
    }

    return next.handle(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            console.error(ErrorMessages.INVALID_CREDENTIALS);
            this._authService.logout();
          } else if (token && error.error && error.error.IsError) {
            console.error(error.error.ResponseMessage);
            this._toastr.error(error.error.ResponseMessage, null, {
              timeOut: 3000,
            });
          }
        }
        return throwError(error);
      })
    );
  }
}
